
import { defineComponent, inject } from "vue";
import { Question, store, storeKey } from "@/services/Store";
import { DefaultBasicUtil, IBasicUtil } from "../interfaces/IBasicUtil";

export default defineComponent({
  name: "Result",
  components: {},
  data() {
    return {
    }
  },
  setup() {
    const { data, restTime, isError } = inject(
      storeKey
    ) as store;
    return {
      data,
      restTime,
      isError,
      basicbasicUtil: inject<IBasicUtil>(DefaultBasicUtil) as IBasicUtil,
    };
  },
  created() {
    const isAuth = this.basicbasicUtil.isAuth("1");
    if (!isAuth) this.$router.push("/auth");
    if (this.data.length == 0) {
      return location.href = "/"
    }
  },
  methods: {
    displayStr(q: Question, val: any){
      if (val == undefined) return ""
      if (q.answerType == 1) {
        return  q["choice" + String(val)]
      }
      if (q.answerType == 2) {
        return String(val).replace(/\|/g, ", ")
      }
      if (q.answerType == 4) {
        if (val == undefined || val == "") return ""
        return val
      }

      return String(val)
    },

    displayAnswer(q: Question, val: any, answer: any){
      if (val == undefined) return ""
      if (q.answerType == 4) {
        if (val == undefined || val == "") return ""
        return Number(val).toLocaleString() == Number(answer).toLocaleString()
      }

      return String(val) == String(answer)
    }
  },
});
