import { Question, Shiken } from "@/services/Store";
import { InjectionKey, Ref } from "vue";

export const DefaultDataService: InjectionKey<IDataService> = Symbol("dataservice");
export interface IDataService {
  getQuestion: () => any

  getShiken: (id: number) => Shiken | null

  getShikens: () => Shiken[]

  getQuestionsFromAnswerType: (shikenId: number, limit: number) => Question[]

  getQuestionFromId: (id: number) => Question | null
}