import { ref, computed, ComputedRef, InjectionKey } from "vue";

export interface store {
  data: ComputedRef<Question[]>;
  isEnded: ComputedRef<boolean>;
  setData: (questions: Question[]) => void;
  setEnded: () => void
  restTime: ComputedRef<number>
  setRestTime: (time: number) => void
  allTime: ComputedRef<number>
  setAllTime: (time: number) => void
  isError: ComputedRef<boolean>
  setIsError: (flg: boolean) => void
}

export class Question {
  id = 0;
  shikenId = 0
  shiken: Shiken|null = null
  parentNo = 0 //問題番号
  no = 0
  /// 1が択一、2がチェックボックス,3が文字列入力, 4が数値入力
  answerType = 0
  /// マルバツの問題文
  question1 = ""
  /// マルバツの問題文
  question2 = ""
  question3 = ""
  choice1 = ""
  choice2 = ""
  choice3 = ""
  choice4 = ""
  choice5 = ""
  choice6 = ""
  choice7 = ""
  choice8 = ""
  choice9 = ""
  choice10 = ""
  /// 1:○2：×、1~4選択肢
  answer = ""
  score = 0
  /// 解説
  lecture = ""

  image = ""

  result = false
  isBookmark = false
  currentChoice = ""
  time = 0
}

export class Shiken {
  id = 0
  no = 0
  name = ""
}

export const storeKey: InjectionKey<store> = Symbol("store");



const _data = ref([] as Question[]);
const _isEnded = ref(false);
const _restTime = ref(-1)
const _allTime = ref(-1)
const _isError = ref(false)


const setData = (questions: Question[]) => {
  _data.value = questions
};


const setEnded = () => {
  _isEnded.value = true
};

const setIsError = (flg: boolean) => {
  _isError.value = flg
};
const setRestTime = (time: number) => {
  _restTime.value = time
};
const setAllTime = (time: number) => {
  _allTime.value = time
};


const data = computed(() => _data.value);
const isEnded = computed(() => _isEnded.value);
const restTime = computed(() => _restTime.value);
const allTime = computed(() => _allTime.value);
const isError = computed(() => _isError.value);


export default { data, isEnded, setData, setEnded,  restTime, setRestTime, allTime, setAllTime, isError, setIsError};