import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4721d04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap p-3 py-4" }
const _hoisted_2 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: "text-r mr-3 form-control",
        type: "text",
        style: {"max-width":"400px"},
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onlyNumber($event))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.answer) = $event))
      }, null, 544), [
        [_vModelText, _ctx.answer]
      ])
    ])
  ]))
}