
import { defineComponent, inject, nextTick, onMounted } from "vue";



import { Question, store, storeKey } from "@/services/Store";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { DefaultBasicUtil, IBasicUtil } from "../../interfaces/IBasicUtil";

export default defineComponent({
  name: "Comp1",
  components: {
  },
  emits: ['answerEmit'],
  props: {
    questions: {
      type: Array as () => Question[],
      required: true
    },
    qIndex: {
      type: Number,
      required: true
    },
    no: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      answer: "" as string,
      question: null as Question|null
    }
  },

  setup(_, { emit }) {
    const { data, setData, isEnded } = inject(storeKey) as store;
    const answerEmit = (value: string) => {
      emit('answerEmit', value);
    };

    return {
      data,
      isEnded,
      setData,
      answerEmit,
      basicbasicUtil: inject<IBasicUtil>(DefaultBasicUtil) as IBasicUtil,
    };
  },

  created() {
    if (this.questions == undefined) return
    let noIndex = 0;
    if (this.no > 0) noIndex = this.no - 1
    this.question = this.data[this.qIndex]
    this.answer = this.data[this.qIndex].currentChoice
  },

  methods: {
    answerExe(i: number) {
      let noIndex = 0;
    if (this.no > 0) noIndex = this.no - 1
      this.data[this.qIndex].currentChoice = String(i)
      this.setData(this.data);
    },
  },
});
