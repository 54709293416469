
import { store, storeKey } from "@/services/Store";
import { computed, defineComponent, inject, ref } from "vue";

export default defineComponent({
  name: "Timer",
  props: {
    qIndex: {
      type: Number,
      required: true
    },
  },
  emits: ["overTime"],
  setup(props, context) {
    const { data, setRestTime, restTime, allTime } = inject(storeKey) as store;
    const allMinutes = Math.floor(allTime.value / 60)
    const restTimeMinutes = Math.floor(restTime.value / 60)
    const gage1 = (allMinutes - restTimeMinutes) / allMinutes
    const gagedata1 = ref<number>(gage1);
    let gage2 = 0
    if (data.value.length > 0) gage2 = ((props.qIndex - 1)) / data.value.length
    const gagedata2 = ref<number>(gage2);
    const overTimeParent = () => {
      context.emit("overTime");
    };
    
    return {
      data,
      gagedata1,
      gagedata2,
      setRestTime,
      overTimeParent,
      restTime,
      allTime,
      allMinutes
    };
  },
  data() {
    return {
      isEnd: false as boolean,
      interval: null as any,
      goalDate: new Date() as any,
      timerStr: "" as string,
      rest: 0 as number,
      restMinutes: 0 as number
    };
  },

  created() {
    this.rest = this.restTime;
    this.start();
  },

  methods: {
    start() {
      if (!this.restTime || this.restTime == 0) return;
      this.goalDate = new Date(+new Date() + this.restTime * 1000);

      const vm = this;
      vm.restMinutes = Math.floor((vm.allTime - vm.rest) / 60)
        vm.gagedata1 = vm.restMinutes / vm.allMinutes
      this.interval = setInterval(function () {
        vm.countdown(vm.goalDate);
        vm.restMinutes = Math.floor((vm.allTime - vm.rest) / 60)
        vm.gagedata1 = vm.restMinutes / vm.allMinutes
        vm.restMinutes = Math.floor((vm.allTime - vm.rest) / 60)
        if (vm.rest <= 0) {
          vm.overTimeParent()
          clearInterval(vm.interval);
          vm.isEnd = true;
        }
      }, 200);
    },

    countdown(due: Date) {
      var now = new Date();
      var rest = due.getTime() - now.getTime();
      this.rest = Math.floor(rest / 1000);
      this.setRestTime(Math.floor(rest / 1000));
    },

    stop() {
      clearInterval(this.interval);
    },


  },
});
