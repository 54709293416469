
export default [
    {
        "id": 1,
        "shikenId": 1,
        "parentNo": 1,
        "no": 1,
        "answerType": 1,
        "question1": "■あるメーカーで、工場ごとの製品製造数を集計しています。<br><br>【工場ごとの製品製造数】<br><img src='/img/1.png' style='width:700px'>",
        "question2": "工場Ｅの午後勤務スタッフ数は何人と推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "69人",
        "choice2": "73人",
        "choice3": "77人",
        "choice4": "81人",
        "choice5": "85人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    }
]